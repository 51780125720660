import React from 'react';
import styled from 'styled-components';
import {useOutlet} from 'reconnect.js';
import withPageEntry from '../../withPageEntry';
import * as AppActions from '../../AppActions';
import * as JStorage from 'rev.sdk.js/Actions/JStorage';

function SurveyList(props) {
  const [user] = useOutlet('user');
  const [records, setRecords] = React.useState(null);

  React.useEffect(() => {
    async function fetchData() {
      AppActions.setLoading(true);
      try {
        const {total, results} = await JStorage.fetchDocuments(
          'survey',
          {public: true, $or: [{users_all: true}, {users: {$in: [user.sub]}}]},
          ['-created'],
          {
            offset: 0,
            limit: 100,
          },
        );

        const {
          total: surveyRespTotal,
          results: surveyRespResults,
        } = await JStorage.fetchDocuments(
          'survey_resp',
          {
            $and: [
              {survey: {$in: results.map((r) => r.id)}},
              {owner: user.sub},
            ],
          },

          ['-created'],
          {
            offset: 0,
            limit: 100,
          },
        );

        if (total > 0) {
          const needResponds = results.filter((r) => {
            return (
              surveyRespResults.findIndex((resp) => resp.survey === r.id) === -1
            );
          });

          setRecords(needResponds);
        }

        AppActions.setLoading(false);
      } catch (err) {
        AppActions.setLoading(false);
      }
    }

    fetchData();
  }, [user]);

  return (
    <Wrapper>
      <div className="container">
        <div className="title-container">
          <h1>問卷填寫</h1>
        </div>
        <div className="content-container">
          {records && records.length === 0 && (
            <div>目前沒有需要投票的問卷。</div>
          )}

          {records &&
            records.length > 0 &&
            records.map((record, index) => (
              <div
                className="item"
                key={`survey-${index}`}
                onClick={() => AppActions.navigate(`/survey?id=${record.id}`)}>
                <div className="name">{record.name}</div>
              </div>
            ))}
        </div>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  margin: var(--topNavBarHeight) auto 0px;
  max-width: var(--contentMaxWidth);
  width: 100%;
  min-height: 100vh;
  padding: 20px;
  background-color: #f3f3f3;

  display: flex;
  justify-content: center;

  & > .container {
    max-width: 1000px;
    width: 100%;
    margin: 30px auto;

    & > .title-container {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    & > .content-container {
      & > .item {
        margin: 10px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        padding: 20px;
        border-radius: 10px;
        display: flex;
        justify-content: space-between;
        align-center: center;
        background-color: white;
        cursor: pointer;

        & > .name {
          font-size: 1rem;
          font-weight: 600;
        }
      }
    }
  }
`;

export default withPageEntry(SurveyList);
